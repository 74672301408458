.section-padding {
  padding: 80px 0;
}

.section-title {
  text-align: center;
  margin-bottom: 60px;
}

.section-title h1 {
  color: var(--text-primary);
  font-family: var(--font-primary);
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

.section-title .lead {
  color: var(--text-secondary);
  font-family: var(--font-primary);
  font-size: 1.25rem;
  font-weight: 400;
}

.card {
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: 100%;
  background-color: var(--card-bg-color);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.card-img-top {
  height: 325px;
  object-fit: cover;
}

.card-body {
  transition: all 0.3s ease;
}

.card-title {
  font-family: var(--font-primary);
  font-weight: 600;
  color: var(--navbar-text);
  margin-bottom: 1rem;
  transition: color 0.3s ease;
  opacity: 0.9;
}

.card-body p.card-text {
  font-family: var(--font-primary);
  color: var(--navbar-text);
  margin-bottom: 1rem;
  transition: color 0.3s ease;
  opacity: 0.9;
}

.card small {
  color: var(--text-secondary);
  transition: all 0.3s ease;
  opacity: 0.7;
}

.btn-outline-primary {
  border-color: var(--primary-color);
  color: var(--primary-color);
  font-family: var(--font-primary);
  padding: 0.5rem 1.5rem;
  transition: all 0.3s ease;
}

.btn-outline-primary:hover {
  background-color: var(--primary-color);
  color: white;
}

.blog-header {
  text-align: center;
  margin-bottom: 3rem;
}

.blog-card {
  border: none;
  box-shadow: 0 2px 15px var(--shadow-color);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px var(--shadow-color);
}

.blog-image-wrapper {
  position: relative;
  overflow: hidden;
}

.blog-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.blog-card:hover .blog-image {
  transform: scale(1.05);
}

.blog-category {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: var(--primary-color);
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
}

.blog-meta {
  display: flex;
  gap: 20px;
  margin-bottom: 1rem;
  color: var(--text-color);
  opacity: 0.7;
  font-size: 0.9rem;
}

.blog-date, .blog-author {
  display: flex;
  align-items: center;
}

.blog-title {
  font-family: var(--heading-font);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.blog-text {
  color: var(--text-color);
  opacity: 0.8;
  margin-bottom: 1.5rem;
}

.read-more {
  border-color: var(--primary-color);
  color: var(--primary-color);
  padding: 8px 20px;
  border-radius: 20px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.read-more:hover {
  background-color: var(--primary-color);
  color: white;
  transform: translateY(-2px);
}

.blog-page {
  padding: 60px 0;
  background-color: var(--background-color);
}

.blog-post {
  margin-bottom: 60px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--border-color);
}

.post-category {
  display: inline-block;
  color: var(--primary-color);
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 1rem;
  letter-spacing: 1px;
}

.post-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--text-color);
  font-weight: 700;
  line-height: 1.3;
}

.post-meta {
  display: flex;
  gap: 20px;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
  color: var(--text-color);
  opacity: 0.7;
}

.post-image {
  margin-bottom: 1.5rem;
  border-radius: 8px;
  overflow: hidden;
}

.post-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.post-image:hover img {
  transform: scale(1.05);
}

.post-excerpt {
  color: var(--text-color);
  line-height: 1.8;
  margin-bottom: 1.5rem;
}

.post-tags {
  margin-bottom: 1.5rem;
}

.tag {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  color: var(--primary-color);
  font-size: 0.9rem;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.tag:hover {
  opacity: 1;
}

.read-more {
  display: inline-block;
  color: var(--primary-color);
  font-weight: 600;
  text-decoration: none;
  transition: color 0.3s ease;
}

.read-more:hover {
  color: var(--secondary-color);
}

/* Sidebar Styles */
.blog-sidebar {
  position: sticky;
  top: 100px;
}

.sidebar-section {
  margin-bottom: 40px;
  padding: 30px;
  background-color: var(--card-bg-color);
  border-radius: 8px;
}

.sidebar-section h3 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: var(--text-color);
}

.tags-cloud {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tags-cloud .tag {
  background-color: var(--primary-color);
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  opacity: 1;
}

.tags-cloud .tag:hover {
  background-color: var(--secondary-color);
}

@media (max-width: 768px) {
  .blog-page {
    padding: 40px 0;
  }

  .post-title {
    font-size: 1.75rem;
  }

  .blog-sidebar {
    margin-top: 40px;
    position: static;
  }
}

.refreshable-header {
  position: relative;
}

.refreshable-header:hover {
  opacity: 0.8;
}

.refreshable-header:active {
  transform: scale(0.98);
} 